export const COMMON = {
  CHANNEL_PARTNER_TOAST: {
    SUCCESS: "Thank You! Signup process completed.",
    ERROR: 'Something went wrong.',
  },

  AYODHYA_PREREGISTRATION_TOAST: {
    SUCCESS: "Thank You! Registration completed.",
    ERROR: 'Something went wrong.',
  }
}

export const EXTERNAL_URLS = {
  platformLink: 'https://platform.realx.in',
  marketPlaceLink:'https://platform.realx.in/dashboard/property/661e6d8b6a19c9fb8a78a2e0'
} 
     
export const STATES = [
    { "label": "Andhra Pradesh", "value": "Andhra Pradesh" },
    { "label": "Arunachal Pradesh", "value": "Arunachal Pradesh" },
    { "label": "Assam", "value": "Assam" },
    { "label": "Bihar", "value": "Bihar" },
    { "label": "Chhattisgarh", "value": "Chhattisgarh" },
    { "label": "Goa", "value": "Goa" },
    { "label": "Gujarat", "value": "Gujarat" },
    { "label": "Haryana", "value": "Haryana" },
    { "label": "Himachal Pradesh", "value": "Himachal Pradesh" },
    { "label": "Jharkhand", "value": "Jharkhand" },
    { "label": "Karnataka", "value": "Karnataka" },
    { "label": "Kerala", "value": "Kerala" },
    { "label": "Madhya Pradesh", "value": "Madhya Pradesh" },
    { "label": "Maharashtra", "value": "Maharashtra" },
    { "label": "Manipur", "value": "Manipur" },
    { "label": "Meghalaya", "value": "Meghalaya" },
    { "label": "Mizoram", "value": "Mizoram" },
    { "label": "Nagaland", "value": "Nagaland" },
    { "label": "Odisha", "value": "Odisha" },
    { "label": "Punjab", "value": "Punjab" },
    { "label": "Rajasthan", "value": "Rajasthan" },
    { "label": "Sikkim", "value": "Sikkim" },
    { "label": "Tamil Nadu", "value": "Tamil Nadu" },
    { "label": "Telangana", "value": "Telangana" },
    { "label": "Tripura", "value": "Tripura" },
    { "label": "Uttar Pradesh", "value": "Uttar Pradesh" },
    { "label": "Uttarakhand", "value": "Uttarakhand" },
    { "label": "West Bengal", "value": "West Bengal" }
  ]

  export const ORGNIZATION_TYPES = [
    { value: "Properietorship", label: "Properietorship" },
    { value: "Partnership", label: "Partnership" },
    { value: "LLP", label: "LLP" },
    { value: "Pvt Ltd", label: "Pvt Ltd" },
    { value: "Public Limited", label: "Public Limited" },
  ];

  export const REALX_REFERENCES_LIST = [
    { label: 'Sayla Estate', value: 'sayla-estate' },
    { label: 'Ican FLIP Advisors', value: 'ican-flip-advisors' },
    { label: 'Crown Realties', value: 'crown-realties' },
    { label: 'AADT RealTech Pvt. Ltd', value: 'aadt-realtech' },
    { label: 'IREF', value: 'iref' },
    { label: 'Others', value: 'others' },
  ];

  export const PLATFORM_OPTIONS = [
    { label: 'Strata', value: 'Strata' },
    { label: 'PropShare', value: 'PropShare' },
    { label: 'hBits', value: 'hBits' },
    { label: 'Others', value: 'Others' },
    // Add more options as needed
  ];